import { ref } from 'vue'
import { useQuery, useQueryClient } from '@tanstack/vue-query'

import { CheckInService } from "@/services";
/**
 * @param {string} clientId - the Client's Trainer's ID
 * @param {{ enabled: boolean }} options - options for the query
*/
export function useReviewedCheckInsForClientQuery(clientId, { enabled }) {
  const queryKey = ['checkIns', 'reviewed', clientId];
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKey,
    queryFn: () => CheckInService.findAllReviewedByClient(clientId).then(res => res.data),
    initialData: /** @type {{data: any}} */ (queryClient.getQueryData(queryKey))?.data,
    enabled: enabled
  })
}

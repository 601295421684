<script setup>
  import { onMounted, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { storeToRefs } from "pinia";

  import CheckInWeightChart from "@/components/Charts/CheckInWeightChart.vue";
  import ClientPageHeader from "@/components/Clients/ClientPageHeader.vue";
  import DisplayGoalSet from "@/components/Clients/DisplayGoalSet.vue";
  import PanelElement from "@/components/ui/PanelElement.vue";
  import SimpleButtonElement from "@/components/ui/SimpleButtonElement.vue";

  import { useClientStore } from "@/stores/ClientStore";
  import { useReviewedCheckInsForClientQuery } from "@/queries/checkIns/useReviewedCheckInsForClientQuery";

  const { currentClient } = storeToRefs(useClientStore());
  const clientStore = useClientStore();
  const route = useRoute();
  const router = useRouter();

  const canQuery = computed(() => {
    return !!route.params.id;
  })

  const { data: checkIns } = useReviewedCheckInsForClientQuery(
    route.params.id,
    {
      enabled: canQuery.value
    }
  )

  onMounted(async () => {
    await clientStore.fetchAndSetCurrentClient(route.params.id);
  });

</script>

<template>
  <div v-if="currentClient">
    <ClientPageHeader :client="currentClient" />
    <div class="space-y-4 mt-6">
      <PanelElement>
        <template #header>
          Weight History
        </template>
        <CheckInWeightChart
          v-if="checkIns"
          :check-ins="checkIns.reverse()"
        />
      </PanelElement>
      <PanelElement>
        <template #header>
          Goals
        </template>
        <template #header-actions>
          <SimpleButtonElement
            color="brand-primary"
            @click="router.push({ name: 'EditClientGoals', params: { currentClient: currentClient.id } })"
          >
            Edit Goals
          </SimpleButtonElement>
        </template>
        <DisplayGoalSet
          v-if="currentClient"
          :goals="currentClient.goals"
        />
      </PanelElement>
    </div>
  </div>
</template>
